import React from 'react'

const SuccessMessage = () => (
  <>
    <h2>Message received</h2>
    <p>
      Thanks, we have received your message and will review it as soon as
      possible.
    </p>
  </>
)

export default SuccessMessage
